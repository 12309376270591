import { useCallback, useMemo, useRef, useState, useSyncExternalStore } from "react";
export function useStateObject(initial) {
    let ref = useRef(initial), [listeners] = useState(()=>new Set()), subscribe = useCallback((cb)=>(listeners.add(cb), ()=>listeners.delete(cb)), []);
    return useMemo(()=>{
        let get = ()=>ref.current;
        return Object.defineProperties(Object.create(null), {
            value: {
                get,
                set: (value1)=>{
                    ref.current !== value1 && (ref.current = value1, listeners.forEach((cb)=>cb()));
                },
                enumerable: !1,
                configurable: !1
            },
            use: {
                value: ()=>useSyncExternalStore(subscribe, get, get),
                enumerable: !1,
                configurable: !1
            }
        });
    }, []);
}
export function useDerivedState(original, transform, update, dependencies) {
    return useMemo(()=>({
            get value () {
                return transform(original.value);
            },
            set value (value){
                original.value = update(original.value, value);
            },
            use: ()=>transform(original.use())
        }), [
        original,
        ...dependencies
    ]);
}
